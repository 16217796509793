<template>
  <div :id="props.scrollable" class="grid grid-cols-2 gap-8 w-full max-w-[320px] md:max-w-full mx-auto relative">
    <div class="col-span-2 absolute top-[-10px] left-0 w-full">
      <button
        v-if="!(props.disablePast && currentMonth.date.isBefore(ExtendedDate.today().getFirstDayOfMonth(), true))"
        :aria-label="t('prev_month')"
        class="aspect-[1/1] bg-white rounded-full drop-shadow-md text-center border border-neutral-100 p-2 h-[40px] leading-[0] float-left"
        @click="currentMonth.prev()"
      >
        <UiIcon src="/svg/daterangepicker/arrow.svg" width="19" height="13" alt="vor" class="rotate-90 inline-block" />
      </button>
      <button
        :aria-label="t('next_month')"
        :class="[{'max-md:hidden' : navigationVerticalMobil}, 'aspect-[1/1] bg-white rounded-full drop-shadow-md text-center border border-neutral-100 p-2 h-[40px] leading-[0] float-right']"
        @click="currentMonth.next()"
      >
        <UiIcon src="/svg/daterangepicker/arrow.svg" width="19" height="13" alt="vor" class="rotate-270 inline-block" />
      </button>
    </div>
    <div v-for="month in monthsList" :key="month" class="col-span-2 md:col-span-1">
      <div class="text-center text-xl font-semibold mb-3">
        {{ t(`monthname_${month.month}`) }}
        {{ month.year }}
      </div>
      <div class="grid grid-cols-7 gap-y-1 text-center text-sm py-2">
        <span v-for="i in 7" :key="i">
          {{ t(`dayname_${i}`) }}
        </span>
      </div>
      <div class="grid grid-cols-7 gap-y-1 text-center">
        <span v-for="day in month.daysBefore" :key="day" class="day dummy">
          {{ day.getDate() }}
        </span>
        <span
          v-for="day in month.days"
          :key="day"
          class="flex items-stretch justify-center"
        >
          <span
            :class="dayClasses(day)"
            :aria-label="getAria(day)"
            v-bind="!dayClasses(day).disabled ? { role: 'button', tabindex: '0' } : {}"
            @click="onClickDay(day)"
            @keydown.enter="onClickDay(day)"
            @mouseenter="onMouseEnterDay(day)"
            @mouseleave="onMouseLeaveDay(day)"
          >
            {{ day.getDate() }}
          </span>
          <div v-if="hasTooltip(day) && props.tooltipActive && tooltipText(day) !== 0" class="tooltip" :style="tooltipClasses(day)">
            {{ tooltipText(day) }} {{ t(`nights.${numerus(tooltipText(day))}`, { nights: tooltipText(day) }) }}
          </div>
        </span>
      </div>
    </div>
  </div>
  <div :class="[{'md:hidden' : navigationVerticalMobil}, {'hidden' : navigationHorizontalMobil}]">
    <button
      :aria-label="t('next_month')"
      class="aspect-[1/1] bg-white rounded-full drop-shadow-md text-center border border-neutral-100 p-2 h-[40px] leading-[0]"
      @click="currentMonth.next()"
    >
      <UiIcon src="/svg/daterangepicker/arrow.svg" width="19" height="13" alt="vor" class="inline-block" />
    </button>
  </div>
  <div v-if="!chosenDates.isEmpty() && resetButton" :class="['grid place-content-end w-full md:w-auto md:p-0', {'fixed md:static bottom-0 left-0 border-t-2 border-neutral-100 md:border-0 bg-white py-2 px-5': resetPositionFixed}]">
    <div class="underline font-semibold cursor-pointer" @click="chosenDates.reset(true)">
      <UiLanguagePlaceholder domain="UiDateRangePicker" name="reset" />
    </div>
  </div>
</template>

<script setup>

const { translate: t, numerus } = useTranslations('UiDateRangePicker')
const { currentPortal } = usePortals()

const onClickDay = (day) => {
  chooseDate(day)
  chosenDates.tooltip = day
  if ('ontouchstart' in window) {
    setTimeout(() => {
      if (chosenDates.tooltip === day) {
        chosenDates.tooltip = null
      }
    }, 2000)
  }
}

const onMouseEnterDay = (day) => {
  previewDate(day)
  startTooltipTimer(day)
}

const onMouseLeaveDay = (day) => {
  clearTooltipTimer(day)
  isHovering.value = false
}

const dayClasses = computed(() => {
  return (day) => {
    return {
      day: true,
      'start-range': day.equals(chosenDates.from),
      'end-range': day.equals(chosenDates.to || chosenDates.toPreview),
      'in-range': day.isBetween(chosenDates.from, chosenDates.to || chosenDates.toPreview, false),
      today: day.equals(ExtendedDate.today()),
      disabled: isDayDisabled.value(day),
      'disabled-to': isDayDisabledTo.value(day)
    }
  }
})

const getAria = computed(() => {
  return (day) => {
    if (!isDayDisabled.value(day)) {
      return day.getAria(currentPortal.value.portal.lang)
    }
  }
})

const isDayDisabled = computed(() => {
  if (chosenDates.isOnlyFirstDateChosen()) {
    return (day) => { return !props.dateRangeCheck(chosenDates.from, day) }
  } else {
    return (day) => { return !props.dateRangeCheck(day) }
  }
})

const isDayDisabledTo = computed(() => _day => false)

const tooltipClasses = (_day) => {
  return { 'margin-top': '-32px' }
}

const tooltipText = computed(() => (day) => {
  if (chosenDates.from && !chosenDates.to) {
    return Math.ceil((new Date(day) - new Date(chosenDates.from)) / (1000 * 60 * 60 * 24))
  }
  return ''
})

const isHovering = ref(false)

const hasTooltip = computed(() => {
  return (day) => {
    if (chosenDates.from && !chosenDates.to) {
      return chosenDates.tooltip === day && (isHovering.value || isAvailableSomehow.value(day))
    }
    return false
  }
})

const makeCalendar = (date, numberOfMonths) => {
  const daysOfMonth = (monthDate) => {
    const days = []
    for (let i = 0; i < monthDate.getMonthDays(); i++) {
      days.push(monthDate.addDays(i))
    }
    return days
  }

  const daysOfMonthBefore = (monthDate) => {
    const firstWeekday = monthDate.getRealWeekday()
    const days = []
    for (let i = 1 - firstWeekday; i < 0; i++) {
      days.push(monthDate.addDays(i))
    }
    return days
  }

  const months = []
  for (let i = 0; i < numberOfMonths; i++) {
    const monthDate = date.addMonths(i)
    months.push({
      year: monthDate.getFullYear(),
      month: monthDate.getMonth() + 1,
      days: daysOfMonth(monthDate),
      daysBefore: daysOfMonthBefore(monthDate)
    })
  }
  return months
}
const currentMonth = reactive({
  date: null,
  maxMonths: null,
  get shownDays () {
    return {
      first: this.date,
      last: this.date.addMonths(this.MaxMonths).addDays(-1)
    }
  },
  setDate (date) {
    this.date = date.getFirstDayOfMonth()
  },
  setMonths (months) {
    this.maxMonths = months
  },
  next () {
    this.date = this.date.addMonths(1)
  },
  addNext () {
    this.maxMonths++
    makeCalendar(this.date, this.maxMonths)
  },
  prev () {
    this.date = this.date.addMonths(-1)
  },
  adapt (from, to) {
    if (!props.isFlex && from?.isValid() && to?.isValid() && !(from.isBetween(this.shownDays.first, this.shownDays.last) && to.isBetween(this.shownDays.first, this.shownDays.last))) {
      this.setDate(from)
    }
  },
  handleScroll () {
    const element = document.getElementById('scrollable').parentElement
    const scrollable = document.getElementById('scrollable')
    const cs = getComputedStyle(element)
    const end = element.scrollTop + element.offsetHeight - scrollable.offsetHeight - parseFloat(cs.paddingTop) - parseFloat(cs.paddingBottom)

    if (end > 0 && currentMonth.maxMonths < 12) {
      currentMonth.addNext()
    }
  },
  checkHeight () {
    const element = document.getElementById('scrollable').parentElement
    const scrollable = document.getElementById('scrollable')

    if (element.offsetHeight > scrollable.offsetHeight) {
      this.addNext()
    }
  }
})

const props = defineProps({
  from: {
    type: String,
    default: ''
  },
  to: {
    type: String,
    default: ''
  },
  dateRangeCheck: {
    type: Function,
    default: () => true
  },
  disablePast: {
    type: Boolean,
    default: true
  },
  months: {
    type: Number,
    default: 2
  },
  buttonActive: {
    type: Boolean,
    default: false
  },
  navigationType: {
    type: String,
    default: 'vertical'
  },
  resetButton: {
    type: Boolean,
    default: true
  },
  datesResetPosition: {
    type: String,
    default: 'fixed'
  },
  tooltipActive: {
    type: Boolean,
    default: false
  },
  isFlex: {
    type: Boolean,
    default: false
  },
  scrollable: {
    type: String,
    default: ''
  }
})

currentMonth.setDate(props.from ? new ExtendedDate(props.from).getFirstDayOfMonth() : ExtendedDate.today())
currentMonth.setMonths(props.months)

let infinite

onMounted(() => {
  if (import.meta.client && props.scrollable === 'scrollable') {
    infinite = document.getElementById('scrollable').parentElement
    currentMonth.checkHeight()
    infinite.addEventListener('scroll', currentMonth.handleScroll)
  }
})

onUnmounted(() => {
  if (import.meta.client && props.scrollable === 'scrollable') {
    infinite.removeEventListener('scroll', currentMonth.handleScroll)
  }
})

const monthsList = computed(() => makeCalendar(
  currentMonth.date,
  currentMonth.maxMonths
))

const navigationVerticalMobil = computed(() => props.navigationType === 'vertical')
const navigationHorizontalMobil = computed(() => props.navigationType === 'horizontal')
const resetPositionFixed = computed(() => props.datesResetPosition === 'fixed')

const emit = defineEmits(['update:from', 'update:to', 'selected:to'])

const chosenDates = reactive({
  from: null,
  to: null,
  tooltip: null,
  setFrom: function (from, doEmit = true) {
    this.from = from
    currentMonth.adapt(this.from, this.to)
    if (doEmit) {
      emit('update:from', from?.dmy ?? '')
    }
  },
  setTo: function (to, doEmit = true) {
    this.to = to
    currentMonth.adapt(this.from, this.to)
    if (doEmit) {
      emit('update:to', to?.dmy ?? '')
    }
  },
  toPreview: null,
  isOnlyFirstDateChosen: function () { return this.from && !this.to },
  isEmpty: function () { return !this.from?.isValid() && !this.to?.isValid() },
  reset: function (tracking = false) {
    this.setFrom(null)
    this.setTo(null)
    chosenDates.toPreview = null
    if (tracking) {
      const { $gtm } = useNuxtApp()
      $gtm.datepickerModule.sendDatepickerResetDateEvent()
    }
  }
})

if (import.meta.client) {
  watch(props, async (props) => {
    await nextTick()

    if (!ExtendedDate.equalsStatic(props?.from, chosenDates.from)) {
      if (props.from === null || props.from === '') {
        chosenDates.reset()
      } else {
        chosenDates.setFrom(new ExtendedDate(props.from), false)
      }
    }
    if (!ExtendedDate.equalsStatic(props?.to, chosenDates.to)) {
      if (props.to === null || props.to === '') {
        chosenDates.setTo(null)
        chosenDates.toPreview = null
      } else {
        chosenDates.setTo(new ExtendedDate(props.to), false)
      }
    }
  }, { immediate: true })
}
const { $gtm } = useNuxtApp()

const chooseDate = (day) => {
  chosenDates.toPreview = null
  if (chosenDates.isOnlyFirstDateChosen()) {
    chosenDates.setTo(day)
    $gtm.datepickerModule.sendDatepickerDepartureDateEvent()
    emit('selected:to', day.dmy)
  } else {
    chosenDates.reset()
    chosenDates.setFrom(day)
    chosenDates.setTo(null)
    $gtm.datepickerModule.sendDatepickerArrivalDateEvent()
  }
}

const previewDate = (day) => {
  if (chosenDates.isOnlyFirstDateChosen()) {
    chosenDates.toPreview = day
  }
}

let tooltipTimer = null
const startTooltipTimer = (day) => {
  if (!('ontouchstart' in window)) {
    tooltipTimer = setTimeout(() => {
      chosenDates.tooltip = day
      isHovering.value = true
    }, 50)
  }
}

const clearTooltipTimer = () => {
  clearTimeout(tooltipTimer)
  chosenDates.tooltip = null
  chosenDates.toPreview = null
  isHovering.value = false
}

const isAvailableSomehow = computed(() => {
  return (day) => {
    if (chosenDates.isOnlyFirstDateChosen()) {
      return props.dateRangeCheck(chosenDates.from, day)
    } else {
      return props.dateRangeCheck(day)
    }
  }
})

defineExpose({
  chosenDates
})

</script>

<style lang="scss" scoped>
.day {
  @apply min-h-[30px] h-full w-full cursor-pointer font-semibold flex items-center justify-center text-sm;
  &.start-range{
    @apply bg-petrol-500 text-white;
    border-radius: 15px 0 0 15px !important;
  }
  &.end-range{
    @apply bg-petrol-500 text-white;
    border-radius: 0 15px 15px 0 !important;
  }
  &.in-range{
    @apply bg-petrol-50;
  }
  &.today{
    @apply border border-petrol-500 rounded-full;
  }
  &.disabled{
    pointer-events: none !important;
    &:not(.start-range){
      color: #cccccc
    }
  }
  &.dummy{
    // color: #cccccc
    visibility: hidden;
  }
}

.tooltip {
  @apply absolute z-[1] bg-neutral-900 text-white text-center min-w-[50px] rounded-md py-1 px-1.5 text-[12px] whitespace-nowrap;
}

.tooltip::after {
  @apply absolute w-[8px] h-[8px] rotate-45 -mt-[5px] bg-neutral-900 top-full left-[calc(50%-4px)] content-[''];
}
</style>
